import { Component, EventEmitter, Output } from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-ql-suggest-address")
@Component({
    selector: "s25-ng-ql-suggest-address",
    template: `
        <div class="wrapper">
            <div class="addressRow">
                <label for="addressType">Address Type</label>
                <select id="addressType" [(ngModel)]="address.type" class="c-input">
                    <option value="(none)">(none)</option>
                    <option value="Administration">Administration</option>
                    <option value="Billing">Billing</option>
                </select>
            </div>
            <div class="addressRow">
                <label for="addressContains">Address Contains</label>
                <input
                    id="addressContains"
                    type="text"
                    [(ngModel)]="address.contains"
                    [maxLength]="78"
                    class="c-input"
                />
            </div>
            <div class="addressRow">
                <label for="addressCity">City</label>
                <input id="addressCity" type="text" [(ngModel)]="address.city" [maxLength]="78" class="c-input" />
            </div>
            <div class="addressRow">
                <label for="addressZip">Zip/Post</label>
                <input id="addressZip" type="text" [(ngModel)]="address.zip" [maxLength]="78" class="c-input" />
            </div>
            <div class="addressRow">
                <label for="addressCountry">Country</label>
                <input id="addressCountry" type="text" [(ngModel)]="address.country" [maxLength]="78" class="c-input" />
            </div>
            <div class="addressRow">
                <label for="addressPhone">Phone</label>
                <input id="addressPhone" type="text" [(ngModel)]="address.phone" [maxLength]="78" class="c-input" />
            </div>
            <div class="addressRow">
                <label for="addressFax">Fax</label>
                <input id="addressFax" type="text" [(ngModel)]="address.fax" [maxLength]="78" class="c-input" />
            </div>
            <div class="buttons">
                <button class="aw-button aw-button--primary" (click)="onDone()">Done</button>
            </div>
        </div>
    `,
    styles: `
        .wrapper {
            min-width: 400px;
            display: flex;
            flex-direction: column;
            gap: 1em;
            border-radius: 3px;
            box-shadow:
                0 7px 14px 0 rgba(50, 50, 93, 0.1),
                0 3px 6px 0 rgba(0, 0, 0, 0.08);
            border: 0;
            padding: 10px;
            background-color: #fff;
        }

        .addressRow {
            display: flex;
            gap: 0.5em;
            justify-content: space-between;
        }

        .buttons {
            display: flex;
            justify-content: flex-end;
        }

        input,
        select {
            min-width: 15em;
        }
    `,
})
export class S25qlSuggestAddressComponent {
    @Output() done = new EventEmitter<SuggestAddress>();

    address: SuggestAddress = {
        type: "(none)",
        contains: "",
        city: "",
        zip: "",
        country: "",
        phone: "",
        fax: "",
    };

    onDone() {
        this.done.emit(this.address);
    }
}

export type SuggestAddress = {
    type: "(none)" | "Administration" | "Billing";
    contains: string;
    city: string;
    zip: string;
    country: string;
    phone: string;
    fax: string;
};
