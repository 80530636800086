//@author: devin
//Migrated By: travis
//@lastmodified: 2021-12-08

import { S25QLConst } from "./s25ql.const";
import { S25Util } from "../../util/s25-util";
import { jSith } from "../../util/jquery-replacement";
import { Brand, Flavor } from "../../pojo/Util";
import { Item } from "../../pojo/Item";
import { SearchCriteria } from "../../pojo/SearchCriteria";
import { ProfileUtil } from "../s25-datepattern/profile.util";

export class QLUtil {
    public static params(opToken: any) {
        if (QLUtil.isListToken(opToken)) {
            return "infinity";
        } else if (opToken.op === "between") {
            return 4;
        } else {
            return 2;
        }
    }

    public static precedence(token: any) {
        var op = token && token.value;
        if (op === "(" || op === ")") {
            return 1;
        } else if (op === "and") {
            return 3;
        } else if (op === "or") {
            return 2;
        } else if (op === "+" || op === "-") {
            return 6;
        } else {
            //all other ops
            return 4;
        }
    }

    public static peek(stack: any) {
        if (stack.length > 0) {
            return stack[stack.length - 1];
        } else {
            return null;
        }
    }

    public static even(num: any) {
        return num % 2 === 0;
    }

    public static posAdj(val: any) {
        return val + S25QLConst.QLPrefix.length;
    }

    public static pushWord(
        currWord: string,
        tokens: any[],
        start: Flavor<number, "index">,
        typeOverride?: any,
        quoteType?: '"' | "'",
    ) {
        if (currWord.length > 0) {
            var isBoolean = ["true", "false"].indexOf(currWord) > -1;
            var isNumeric = $.isNumeric(currWord);
            var isDate = false,
                isTime = false;

            if (
                (currWord.indexOf("'") > -1 || currWord.indexOf('"') > -1) &&
                S25QLConst.dateRegex.test(currWord.replace(/['"]/g, ""))
            ) {
                isDate = S25Util.date.isValid(currWord.replace(/['"]/g, ""));
            } else if (
                (currWord.indexOf("'") > -1 || currWord.indexOf('"') > -1) &&
                S25QLConst.timeRegex.test(currWord.replace(/['"]/g, ""))
            ) {
                isTime = true;
            }

            var type =
                typeOverride ||
                (isTime
                    ? "time"
                    : isDate
                      ? "date"
                      : isBoolean
                        ? "boolean"
                        : isNumeric
                          ? "number"
                          : currWord.indexOf("'") > -1 || currWord.indexOf('"') > -1
                            ? "string"
                            : "term");

            tokens.push({
                value: currWord,
                type: type,
                start: QLUtil.posAdj(start),
                end: QLUtil.posAdj(start + currWord.length),
                quoteType: type === "string" ? quoteType : null,
            });
        }
    }

    public static pushOp(currOp: any, tokens: any[], start: Flavor<number, "index">) {
        //console.log(currOp, start);
        tokens.push({
            value: currOp,
            type: "op",
            start: QLUtil.posAdj(start),
            end: QLUtil.posAdj(start + currOp.length),
        });
    }

    public static infixToPostfix(tokens: any) {
        var stack = [],
            output = [],
            stackVal: any = "";
        for (var i = 0; i < tokens.length; i++) {
            var token = tokens[i];
            if (token.type === "dummy" && stack.length > 0) {
                //dummy used for "between" and in general to just exist as a stand-in, so yield to stack (if not owner term) and then add self
                S25QLConst.hasDummy.indexOf(QLUtil.peek(stack).value) === -1 && output.push(stack.pop());
                output.push(token);
            } else if (token.type === "op") {
                while (
                    stack.length > 0 &&
                    token.value !== "(" &&
                    QLUtil.precedence(token) <= QLUtil.precedence(QLUtil.peek(stack))
                ) {
                    stackVal = stack.pop();
                    if (token.value === ")" && stackVal.value === "(") {
                        break;
                    }
                    if (["(", ")"].indexOf(stackVal.value) === -1) {
                        output.push(stackVal);
                    }
                }
                [")"].indexOf(token.value) === -1 && stack.push(token); //don't push end-parens, we already dealt with it above...
            } else {
                if (["(", ")"].indexOf(token.value) === -1) {
                    output.push(token);
                }
            }
        }

        while (stack.length > 0) {
            stackVal = stack.pop();
            if (["(", ")"].indexOf(stackVal.value) === -1) {
                output.push(stackVal);
            }
        }
        return output;
    }

    public static switchParent(newParent: any) {
        return function (obj: any) {
            obj.parent = newParent;
            return obj;
        };
    }

    public static toStr(str: any) {
        str = S25Util.coalesce(str, "");
        str = str + "";
        str = str.replace("''", "'").replace('""', '"');
        var start = 0,
            newLen = str.length;
        if (["'", '"'].indexOf(str[0]) > -1) {
            start = 1;
            newLen -= 1;
        }
        if (["'", '"'].indexOf(str[str.length - 1]) > -1) {
            newLen -= 1;
        }
        return str.substr(start, newLen);
    }

    public static escStr(str: any) {
        return QLUtil.toStr(str).replace(/'/g, "''").replace(/"/g, '""');
    }

    public static isPrimitive(obj: any) {
        return obj && ["date", "time", "string", "number", "boolean", "term"].indexOf(obj.type) > -1;
    }

    public static isNative(obj: any) {
        return obj && ["date", "time", "string", "number", "boolean"].indexOf(obj.type) > -1;
    }

    public static allButOp(obj: any) {
        return QLUtil.onlyValues(obj) || QLUtil.onlyTerm(obj);
    }

    public static onlyValues(obj: any) {
        return obj.type !== "term" && !obj.op && obj.type !== "dummy";
    }

    public static onlyValuesOrSugar(obj: any) {
        return QLUtil.onlyValues(obj) || QLUtil.isSugar(obj);
    }

    public static onlyOp(obj: any) {
        return obj.op || obj.type === "op";
    }

    public static onlyTerm(obj: any) {
        return obj.type === "term";
    }

    public static isSugar(obj: any) {
        return QLUtil.onlyTerm(obj) && S25QLConst.dateSugar.indexOf(obj.value) > -1;
    }

    public static notSugar(obj: any) {
        return !QLUtil.isSugar(obj);
    }

    public static notDummy(obj: any) {
        return obj.type !== "dummy";
    }

    public static onlyNumbers(obj: any) {
        return obj.typeCheckType === "number" || obj.type === "number";
    }

    public static onlyDates(obj: any) {
        return obj.typeCheckType === "date" || obj.type === "date";
    }

    public static onlyStrings(obj: any) {
        return obj.typeCheckType === "string" || obj.type === "string";
    }

    public static types(obj: any) {
        return obj.typeCheckType || obj.type;
    }

    public static returnValue(obj: any) {
        return obj.value;
    }

    public static isStartToken(token: any) {
        return token.value === "(" && token.type === "op";
    }

    public static isEndToken(token: any) {
        return token.value === ")" && token.type === "op";
    }

    public static getClosestTerms(
        str: string,
        searchType: Item.Ids.Event | Item.Ids.Organization | Item.Ids.Location | Item.Ids.Resource | Item.Ids.Task,
    ) {
        var dists = [];
        var terms = S25QLConst[searchType];
        for (var t in terms) {
            if (terms.hasOwnProperty(t)) {
                dists.push({ term: t, dist: S25Util.minEditDist(str, t) });
            }
        }
        jSith.forEach(dists, function (_, obj) {
            if (obj.term.indexOf(str) === 0) {
                //if obj term starts with str, move it up...
                obj.dist -= 100 * str.length; //longer matching prefix move up higher
            }
        });
        return dists.sort(S25Util.shallowSort("dist", true)).map(function (obj) {
            return obj.term;
        });
    }

    public static padQuotes(str: string) {
        str = str || ""; //if str undefined, set to empty str
        var singleQuote = 0,
            doubleQuote = 0;
        for (var i = 0; i < str.length; i++) {
            if (str[i] === "'" && QLUtil.even(doubleQuote)) {
                singleQuote++;
            } else if (str[i] === '"' && QLUtil.even(singleQuote)) {
                doubleQuote++;
            }
        }

        //only 1 may be non-even bc the first non-even one makes any occ of the other one even until it ends
        //eg, foo "...''' --> only has non-even " bc the three ' are contained in the "
        if (!QLUtil.even(singleQuote)) {
            str += "'";
        } else if (!QLUtil.even(doubleQuote)) {
            str += '"';
        }

        if (str === "''" || str === '""') {
            //if string is now just two quotes, return empty string...
            str = "";
        }

        return str;
    }

    //render tokens to string
    public static tokensToString(tokens: any) {
        var str = "";
        for (var i = 0; i < tokens.length; i++) {
            if (tokens[i]) {
                var val = tokens[i].value;
                var type = tokens[i].type;
                var spaceAfter = " ";
                var commaAfter = "";
                if (QLUtil.isStartToken(tokens[i])) {
                    spaceAfter = "";
                }
                if (
                    ["string", "date", "time", "number"].indexOf(type) > -1 &&
                    i + 1 < tokens.length &&
                    QLUtil.isEndToken(tokens[i + 1])
                ) {
                    spaceAfter = "";
                }
                if (type === "string" && !QLUtil.toStr(val)) {
                    spaceAfter = "";
                }
                if (QLUtil.isEndToken(tokens[i]) && i + 1 < tokens.length && QLUtil.isEndToken(tokens[i + 1])) {
                    spaceAfter = "";
                }
                if (type === "string" && i + 1 < tokens.length && tokens[i + 1].type === "string") {
                    commaAfter = ",";
                }
                str += val + commaAfter + spaceAfter;
            }
        }
        return S25QLConst.QLPrefix + str;
    }

    public static updateUUID(tokens: any[]) {
        for (let [i, token] of Object.entries(tokens)) {
            if (token) token.uuid = `word_${i}`;
        }
        return tokens;
    }

    public static isEmbedded(token: any) {
        var s = token && token.type === "string" && token.value && QLUtil.toStr(token.value).split(":");
        return (
            s &&
            s.length === 2 &&
            S25QLConst.embeddedIds.indexOf(s[0]) > -1 &&
            S25Util.isDefined(parseInt(s[1])) &&
            true
        );
    }

    public static getFirstEmbeddedAndIndexAfterToken(currToken: any, tokens: any) {
        var currTokenIndex;
        for (var i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i].uuid === currToken.uuid) {
                currTokenIndex = i;
            }

            if (currTokenIndex < i && QLUtil.isEmbedded(tokens[i])) {
                return { index: i, token: tokens[i] };
            }
        }
        return null;
    }

    public static getFirstBooleanAndIndexAfterToken(currToken: any, tokens: any) {
        var currTokenIndex;
        for (var i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i].uuid === currToken.uuid) {
                currTokenIndex = i;
            }

            if (currTokenIndex < i && QLUtil.isBoolOp(tokens[i])) {
                return { index: i, token: tokens[i] };
            }
        }
        return null;
    }

    public static getFirstTermAndIndexBeforeToken(currToken: any, tokens: any) {
        var currTokenIndex;
        for (var i = tokens.length - 1; i >= 0; i--) {
            if (tokens[i] && tokens[i].uuid === currToken.uuid) {
                currTokenIndex = i;
            }

            if (currTokenIndex > i && tokens[i] && tokens[i].type === "term") {
                return { index: i, token: tokens[i] };
            }
        }
        return null;
    }

    public static getFirstTermBeforeToken(currToken: any, tokens: any) {
        var termAndIndex = QLUtil.getFirstTermAndIndexBeforeToken(currToken, tokens);
        return termAndIndex && termAndIndex.token;
    }

    public static getTokensBetween(tokens: any, start: any, end: any) {
        var result = [];
        for (var i = 0; i < tokens.length; i++) {
            if (tokens[i] && tokens[i].end > start && tokens[i].start < end) {
                result.push(tokens[i]);
            }
        }
        return result;
    }

    public static tokenNeedsId(token: any, term: any, searchType: any) {
        if (token && token.type === "string" && term && !QLUtil.isEmbedded(token)) {
            var termMeta = S25QLConst[searchType][term.value];
            return termMeta && termMeta.hasSuggestions && termMeta.type === "number";
        }
        return false;
    }

    public static isListToken(token: any) {
        return (
            token && (token.type === "op" || token.op) && ["in", "notIn", "all"].indexOf(token.op || token.value) > -1
        );
    }

    public static isStartOrEnd(token: any) {
        return QLUtil.isStartToken(token) || QLUtil.isEndToken(token);
    }

    public static isBoolOp(token: any) {
        // @ts-ignore -- Ignoring this because I don't know when "op" exists on "token"
        return token && (token.type === "op" || token.op) && S25QLConst.metaOps.indexOf(token.op || token.value) > -1;
    }

    public static isPlusMinusOp(token: any) {
        return token && token.type === "op" && ["+", "-"].indexOf(token.value) > -1;
    }

    public static isCompOp(token: any) {
        return token && token.type === "op" && S25QLConst.comparisonOps.indexOf(token.value) > -1;
    }

    public static isFullTerm(token: any, searchType: any) {
        return !!(token && token.type === "term" && S25QLConst[searchType][token.value]);
    }

    public static isPartialTerm(token: any, searchType: any) {
        return token && token.type === "term" && !QLUtil.isFullTerm(token, searchType);
    }

    public static isBooleanPrefix(token: any) {
        //strict prefix (eg, not an exact match of any value)
        for (var i = 0; i < S25QLConst.metaOps.length; i++) {
            var metaOp = S25QLConst.metaOps[i];
            if (token && token.value.length < metaOp.length && metaOp.indexOf(token.value) === 0) {
                return true;
            }
        }
        return false;
    }

    public static isTermPrefix(token: any, searchType: any) {
        //strict prefix (eg, not an exact match of any value)
        for (var t in S25QLConst[searchType]) {
            if (S25QLConst[searchType].hasOwnProperty(t)) {
                if (token && token.value.length < t.length && t.indexOf(token.value) === 0) {
                    return true;
                }
            }
        }
        return false;
    }

    public static isComparisonOpPrefix(token: any) {
        //strict prefix (eg, not an exact match of any value)
        for (var i = 0; i < S25QLConst.comparisonOps.length; i++) {
            var COp = S25QLConst.comparisonOps[i];
            if (token && token.value.length < COp.length && COp.indexOf(token.value) === 0) {
                return true;
            }
        }
        return false;
    }

    public static truthy(val: any) {
        if (S25Util.toBool(val)) {
            if (val === "F") {
                //treat "F" as "false" (and T as true)
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    public static massageTokens(tokens: any, typeId: any) {
        jSith.forEach(tokens, function (_, t) {
            if (S25Util.isDefined(t.id)) {
                //set value to id used for query engine
                if (QLUtil.isEmbedded(t)) {
                    //in case id has been set in canonical tokens somehow for an embedded value, handle splitting it up here...
                    var s = QLUtil.toStr(t.value).split(":");
                    t.itemName = s[0];
                    t.value = "" + s[1];
                    t.id = t.value;
                } else {
                    t.itemName = QLUtil.toStr(t.value);
                    t.value = "" + t.id;
                }

                t.type = "number";
            } else if (t.type === "string" || t.type === "date" || t.type === "time") {
                if (QLUtil.isEmbedded(t)) {
                    //if token has id embedded in it, eg role:-1, use it as id
                    var s = QLUtil.toStr(t.value).split(":");
                    t.itemName = QLUtil.toStr(t.value);
                    t.value = s[1];
                    t.type = "number";
                } else {
                    //if token's term has forceAsNumber set, force numerical value while keeping original value as itemName and set type to number
                    var term = QLUtil.getFirstTermBeforeToken(t, tokens);
                    if (term && S25QLConst[typeId][term.value] && S25QLConst[typeId][term.value].forceAsNumber) {
                        t.id = 1; //set artificial id to force as number
                        t.itemName = QLUtil.toStr(t.value); //save value into itemName for converting between design view and sql view, etc
                        t.value = t.id; //set value as the artificial id / number
                        t.type = "number"; //set type as number
                        t.forced = true; //indicate it was forced
                    }
                }
            }
        });
    }

    public static cleanQL(str: Flavor<string, "ql">): Brand<string, "ql"> {
        return (str.startsWith(S25QLConst.QLPrefix) ? str.substring(S25QLConst.QLPrefix.length) : str) as Brand<
            string,
            "ql"
        >;
    }

    public static isTemp(obj: any) {
        return obj?.indexOf?.("temp_") === 0;
    }

    public static isStepSearch(step: SearchCriteria.Step) {
        const stepType = Number(step.step_type_id); // Event search is 108, all others are X05
        return stepType === SearchCriteria.StepType.Event.EventSearch || (stepType !== 105 && stepType % 100 === 5);
    }

    public static isStepTemp(step: SearchCriteria.Step) {
        return QLUtil.isStepSearch(step) && step.step_param?.[0]?.itemName?.startsWith?.("temp_");
    }

    public static ProfileCode = {
        WILDCARD: "[0-9#]%",
        //only show needed parts of queryCode
        removeTimeDefaults(queryCode: string) {
            return queryCode.replace("hh:mm|hh:mm|", "");
        },
        //adds start/end times and wildcard to profileCode
        addTimeDefaults(profileCode: string) {
            if (profileCode.lastIndexOf("|") === 11 && profileCode.indexOf("|") === 5) {
                return profileCode;
            }
            //10:30|W1 MO....
            return `hh:mm|hh:mm|${profileCode}`;
        },

        //TODO: Clean up any searches in the db that store this WILDCARD
        //Short term strip off the wildcard
        getProfileCodeWithoutWildcard(profileCode: string) {
            return profileCode.replace(QLUtil.ProfileCode.WILDCARD, "");
        },
    };
}
