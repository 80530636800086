import { Brand, Flavor } from "../../pojo/Util";
import { QLTerm, S25QLConst } from "./s25ql.const";
import { Proto } from "../../pojo/Proto";
import { Item } from "../../pojo/Item";
import { S25QLModeller } from "./s25ql.modeller";
import ISODateString = Proto.ISODateString;
import Query = Tokenizer.Query;
import Clause = Tokenizer.Clause;
import Value = Tokenizer.Value;
import StringValue = Tokenizer.StringValue;
import MathValue = Tokenizer.MathValue;
import DateValue = Tokenizer.DateValue;
import BooleanValue = Tokenizer.BooleanValue;
import NumberValue = Tokenizer.NumberValue;
import TimeValue = Tokenizer.TimeValue;
import RangeValue = Tokenizer.RangeValue;
import ListValue = Tokenizer.ListValue;
import EmbeddedValue = Tokenizer.EmbeddedValue;
import CustomAttributeValue = Tokenizer.CustomAttributeValue;
import QLItemTypes = Tokenizer.QLItemTypes;
import Conjunction = Tokenizer.Conjunction;
import LowerStringBoolean = Proto.LowerStringBoolean;
import Term = Tokenizer.Term;
import Operator = Tokenizer.Operator;
import Operation = QLTerm.Operation;
import KeywordValue = Tokenizer.KeywordValue;

export namespace Tokenizer {
    import Ids = Item.Ids;
    export type Token<T extends string = string, Y = any> = {
        type: T;
        value: Y;
        start: number; // Start index in query string
        end: number; // End index in query string
        hasCaret?: boolean; // Only used when serializing the query
    };
    export type Tokens = Query | Clause | Conjunction | Term | Operator | Value;
    export type TokenType = Tokens["type"];
    export type Query = Token<"query", (Query | Clause | Conjunction)[]> & { closed: boolean };
    export type Clause = Token<"clause", { term: Term; operator: Operator; value: Value }>;
    export type Conjunction = Token<"conjunction", "and" | "or">;

    export type Term = Token<"term", string>;
    export type Operator = Token<"operator", string>;

    export type Value =
        | NumberValue
        | StringValue
        | DateValue
        | TimeValue
        | BooleanValue
        | MathValue<any>
        | ListValue<any>
        | EmbeddedValue
        | CustomAttributeValue
        | RangeValue<any>
        | KeywordValue
        | ProfileCodeValue;

    export type NumberValue = Token<"number", number>;
    export type StringValue = Token<"string", string>;
    export type ProfileCodeValue = Token<"profileCode", string>;
    export type KeywordValue = Token<"keyword", string>;
    export type DateValue = Token<"date", ISODateString>;
    export type TimeValue = Token<"time", Flavor<string, "hh:mm">>;
    export type BooleanValue = Token<"boolean", LowerStringBoolean>;
    export type ListValue<T extends StringValue | (NumberValue | MathValue)> = Token<"list", T[]>;
    export type MathValue<T = never> = Token<"math", (T | NumberValue | MathValue<T> | Operator)[]> & {
        wrapped: boolean;
    };

    export type EmbeddedValue = Token<
        "embedded",
        {
            embeddedType: string;
            embeddedValue: number;
            values: StringValue[];
        }
    >;

    export type CustomAttributeValue = Token<
        "customAttribute",
        {
            name: string;
            operator: CustomAttributeOperator;
            type: string;
            value: string | number | ISODateString | Brand<string, "hh:mm">;
        }
    >;

    export type RangeValue<
        T extends NumberValue | DateValue | TimeValue,
        _Y = T extends TimeValue ? TimeValue : MathValue<T>,
    > = Token<"range", { start: _Y; end: _Y }>;

    export type CustomAttributeOperator =
        | "is True"
        | "is False"
        | "exists"
        | "does not exist"
        | "is equal to"
        | "contains"
        | "starts with"
        | "is earlier than or equal to"
        | "is later than or equal to"
        | "is less than or equal to"
        | "is greater than or equal to";

    export type QLItemTypes = Ids.Event | Ids.Location | Ids.Resource | Ids.Organization | Ids.Task;
}

export type GetValueReturn<T> = { value: T; remaining: string; error?: SyntaxError };

function setError<T extends { error?: SyntaxError }>(obj: T, error: SyntaxError) {
    obj.error = error;
    return obj;
}

function setValue<T extends Value>(obj: GetValueReturn<T>, value: T["value"], remaining: string) {
    obj.value.value = value;
    obj.remaining = remaining;
    return obj;
}

function tokenizer(str: Flavor<string, "ql">, type: QLItemTypes): Query {
    const withoutPrefix = str.replace(/^[\s\n\t]*::/, "");
    const query = parseQuery(withoutPrefix, type, str.length - withoutPrefix.length);
    if (query.error) throw query.error;
    validate(type, query.value); // Will throw if invalid
    return query.value;
}

function safeTokenizer(str: string, type: QLItemTypes): GetValueReturn<Query> {
    const withoutPrefix = str.replace(/^[\s\n\t]*::/, "");
    return parseQuery(withoutPrefix, type, str.length - withoutPrefix.length);
}

function parseQuery(str: string, type: QLItemTypes, start: number = 0): GetValueReturn<Query> {
    const ret: GetValueReturn<Query> = {
        value: { type: "query", value: [], closed: false, start, end: null },
        remaining: str,
        error: null,
    };

    let lastConjunction: Conjunction;
    do {
        if (ret.value.value.length) {
            const matchConjunction = ret.remaining.match(/^[\s\n\t]*(?<conjunction>\w*)(?<remaining>[\s\S]*)/);
            if (!/^(and|or)$/.test(matchConjunction?.groups?.conjunction || "")) {
                ret.value.value.push({
                    type: "conjunction",
                    value: (matchConjunction?.groups?.conjunction || "") as "and" | "or",
                    start: start + (str.length - ret.remaining.length),
                    end: null,
                });
                return setError(ret, new SyntaxError(`Missing or invalid conjunction between clauses`));
            }
            const conjunction: Conjunction = {
                type: "conjunction",
                value: matchConjunction?.groups?.conjunction as "and" | "or",
                start: start + (str.length - ret.remaining.length),
                end: start + (str.length - matchConjunction.groups.remaining.length),
            };

            // If we have mixed "and"/"or" conjunctions, then we need to make the preceding clauses their own query
            if (lastConjunction && conjunction.value !== lastConjunction.value) {
                ret.value.value = [
                    {
                        type: "query",
                        value: ret.value.value,
                        closed: true,
                        start: ret.value.start,
                        end: conjunction.start,
                    },
                ];
            }
            ret.value.value.push(conjunction);
            lastConjunction = conjunction;

            ret.remaining = matchConjunction.groups.remaining;
        }

        // If wrapped in parentheses, then parse it as another query, else parse it a clause
        if (/^[\s\n\t]*\(/.test(ret.remaining)) {
            ret.remaining = ret.remaining.replace(/^[\s\n\t]*\([\s\n\t]*/, ""); // Remove "("
            const query = parseQuery(ret.remaining, type, start + (str.length - ret.remaining.length));
            if (query.value.value.length === 1) ret.value.value.push(query.value.value[0]);
            else ret.value.value.push(query.value);
            ret.remaining = query.remaining;
            if (query.error) return setError(ret, query.error);
            if (!/^[\s\n\t]*\)/.test(ret.remaining))
                return setError(ret, new SyntaxError(`Missing closing parenthesis`));
            query.value.closed = true;
            ret.remaining = ret.remaining.replace(/^[\s\n\t]*\)[\s\n\t]*/, ""); // Remove ")"
        } else {
            const clause = parseClause(ret.remaining, type, start + (str.length - ret.remaining.length));
            ret.value.value.push(clause.value);
            ret.remaining = clause.remaining;
            if (clause.error) return setError(ret, clause.error);
        }

        // Stop if there are no more clauses or conjunctions
        if (!ret.remaining || /^[\s\n\t]*\)/.test(ret.remaining)) break;
    } while (ret.remaining.trim());

    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseClause(str: string, type: QLItemTypes, start: number): GetValueReturn<Clause> {
    const ret: GetValueReturn<Clause> = {
        value: { type: "clause", value: { term: null, operator: null, value: null }, start, end: null },
        remaining: str,
        error: null,
    };

    // Get term
    const term = parseTerm(ret.remaining, start);
    ret.value.value.term = term.value;
    ret.value.end = term.value.end;
    if (term.error) return setError(ret, term.error);
    if (!S25QLConst.terms[type][term.value.value]) return setError(ret, new SyntaxError("Invalid term"));
    ret.remaining = term.remaining;

    // Get operator
    const operator = parseOperator(ret.remaining, start + (str.length - ret.remaining.length));
    ret.value.value.operator = operator.value;
    ret.value.end = operator.value.end;
    if (operator.error) return setError(ret, operator.error);
    if (!S25QLConst.terms[type][term.value.value].operations.includes(operator.value.value as Operation))
        return setError(ret, new SyntaxError("Invalid operator"));
    ret.remaining = operator.remaining;

    // Get value
    const value = parseValue(ret.remaining, type, term.value.value, start + (str.length - ret.remaining.length));
    ret.value.value.value = value.value;
    ret.value.end = value.value.end;
    ret.remaining = value.remaining;
    if (value.error) return setError(ret, value.error);

    return ret;
}

function parseTerm(str: string, start: number): GetValueReturn<Term> {
    const ret: GetValueReturn<Term> = {
        value: { type: "term", value: null, start, end: null },
        remaining: str,
        error: null,
    };

    const matchTerm = ret.remaining.match(/^[\s\n\t]*(?<term>[A-Z]+)(?<remaining>[\s\S]*)/i);
    if (!matchTerm?.groups?.term) return setError(ret, new SyntaxError("Clause must start with a term"));
    ret.value.value = matchTerm.groups.term;
    ret.remaining = matchTerm.groups.remaining;
    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseOperator(str: string, start: number): GetValueReturn<Operator> {
    const ret: GetValueReturn<Operator> = {
        value: { type: "operator", value: null, start, end: null },
        remaining: str,
        error: null,
    };

    // Parses an operator (e.g. "contains", "=", or ">="), preceded by any amount of whitespace.
    // Also captures the remaining string after the operator
    const matchOperator = ret.remaining.match(/^[\s\n\t]*(?<operator>([A-Z]+|[=<>]+))(?<remaining>[\s\S]*)/i);
    if (!matchOperator?.groups?.operator)
        return setError(ret, new SyntaxError("A term must be followed by an operator"));
    ret.value.value = matchOperator.groups.operator;
    ret.remaining = matchOperator.groups.remaining;
    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseValue(remaining: string, type: QLItemTypes, term: string, start: number): GetValueReturn<Value> {
    const termData = S25QLConst.terms[type][term];
    const valueType: keyof typeof parseValueMap = termData.type;
    const tokenType = valueType.endsWith("Range") ? "range" : valueType.endsWith("List") ? "list" : valueType;

    const ret: GetValueReturn<Value> = {
        value: { type: tokenType as any, value: null, start, end: null },
        remaining,
        error: null,
    };

    if (/^[\s\n\t]*$/.test(remaining)) return setError(ret, new SyntaxError(`An operator must be followed by a value`));

    const getValue = parseValueMap[valueType];
    return getValue(remaining, start);
}

const getNumberValue = parseMathValue.bind(null, "number", []);
const getDateRangeValue = parseMathValue.bind(null, "date", [
    parseDateValue,
    parseNumberValue, // Number must come before keyword
    parseKeywordValue.bind(null, ["today", "start"]),
]);
const getDateValue = parseMathValue.bind(null, "date", [
    parseDateValue,
    parseNumberValue, // Number must come before keyword
    parseKeywordValue.bind(null, ["today"]),
]);
const parseValueMap = {
    string: parseStringValue,
    number: getNumberValue,
    boolean: parseBooleanValue,
    date: getDateValue,
    time: parseTimeValue,
    dateRange: parseRangeValue.bind(null, "date", getDateRangeValue),
    timeRange: parseRangeValue.bind(null, "time", parseTimeValue),
    numberRange: parseRangeValue.bind(null, "number", getNumberValue),
    numberList: parseListValue.bind(null, [getNumberValue]),
    stringList: parseListValue.bind(null, [parseStringValue]),
    stringOrNumberList: parseListValue.bind(null, [parseStringValue, getNumberValue]),
    embedded: parseEmbeddedValue,
    customAttribute: parseCustomAttributeValue,
    profileCode: parseListValue.bind(null, [parseStringValue]),
};

function parseStringValue(str: string, start: number): GetValueReturn<StringValue> {
    const ret: GetValueReturn<StringValue> = {
        value: { type: "string", value: null, start, end: null },
        remaining: str,
        error: null,
    };

    const quoteType = ret.remaining.match(/^[\s\n\t]*(?<quote>['"])/)?.[1];
    if (!quoteType)
        return setError(ret, new SyntaxError("A string value must be encapsulated in either double or single quotes"));
    const matchString = ret.remaining.match(
        RegExp(`^[\\s\\n\\t]*${quoteType}(?<value>[^${quoteType}]*)${quoteType}[\\s\\n\\t]*(?<remaining>[\\s\\S]*)`),
    );
    if (!matchString?.groups?.value) return setError(ret, new SyntaxError(`Invalid string value`));
    ret.value.end = start + (str.length - matchString.groups.remaining.length);
    return setValue(ret, matchString.groups.value, matchString.groups.remaining);
}

function parseNumberValue(str: string, start: number): GetValueReturn<NumberValue> {
    const ret: GetValueReturn<NumberValue> = {
        remaining: str,
        error: null,
        value: {
            type: "number",
            value: null,
            start,
            end: null,
        },
    };
    const matchNumber = str.match(/^[\s\n\t]*(?<value>[-+]?[\d.]+)(?<remaining>[\s\S]*)/);
    if (!matchNumber?.groups?.value) return setError(ret, new SyntaxError(`Invalid number value`));
    ret.value.value = parseFloat(matchNumber.groups.value);
    ret.value.end = start + (str.length - matchNumber.groups.remaining.length);
    ret.remaining = matchNumber.groups.remaining;
    return ret;
}

function parseMathOperator(str: string, start: number): GetValueReturn<Operator> {
    const ret: GetValueReturn<Operator> = {
        remaining: str,
        error: null,
        value: {
            type: "operator",
            value: null,
            start,
            end: null,
        },
    };

    const matchOperator = ret.remaining.match(/^[\s\n\t]*(?<operator>[+\-])(?<remaining>[\s\S]*)/);
    if (!matchOperator?.groups?.operator) return setError(ret, new SyntaxError(`Invalid operator`));
    ret.value.value = matchOperator.groups.operator as "+" | "-";
    ret.value.end = start + (str.length - matchOperator.groups.remaining.length);
    ret.remaining = matchOperator.groups.remaining;
    return ret;
}

function evaluateParsers<T>(
    parsers: ((str: string, start: number) => GetValueReturn<T>)[],
    str: string,
    start: number,
): GetValueReturn<T> {
    for (let valueParser of parsers) {
        const parsed = valueParser(str, start);
        if (!parsed.error) return parsed;
    }
}

function parseMathValueValue<T = never>(
    type: "date" | "number",
    parsers: ((str: string, start: number) => GetValueReturn<T>)[],
    str: string,
    start: number,
    ret: GetValueReturn<MathValue<T>>,
) {
    // If string starts with a "(" then we recurse
    if (/^[\s\n\t]*\(/.test(ret.remaining)) {
        const leftString = ret.remaining.replace(/^[\s\n\t]*\(/, "");
        const left = parseMathValue(type, parsers, leftString, start + (str.length - leftString.length), true);
        if (left.error) return setError(ret, left.error);
        ret.value.value.push(left.value);
        if (!/^[\s\n\t]*\)/.test(left.remaining))
            return setError(ret, new SyntaxError(`Missing corresponding closing parenthesis in math expression`));
        ret.remaining = left.remaining.replace(/^[\s\n\t]*\)/, ""); // Remove the corresponding ")"
    } else {
        const value = evaluateParsers<T | NumberValue>(parsers, str, start);
        if (!value) return setError(ret, new SyntaxError(`Invalid ${type} value`));
        ret.value.value.push(value.value);
        ret.remaining = value.remaining;
    }
}

function parseMathValue<T = never>(
    type: "number" | "date",
    parsers: ((str: string, start: number) => GetValueReturn<T>)[],
    str: string,
    start: number,
    wrapped: boolean = false,
): GetValueReturn<MathValue<T>> {
    const allParsers = [...parsers, parseNumberValue]; // Since this is math, numbers are always included
    const ret: GetValueReturn<MathValue<T>> = {
        value: {
            type: "math",
            wrapped,
            value: [],
            start,
            end: null,
        },
        remaining: str,
        error: null,
    };

    parseMathValueValue(type, allParsers as any, str, start, ret);
    if (ret.error) return ret;

    while (true) {
        const operator = parseMathOperator(ret.remaining, start + (str.length - ret.remaining.length));
        if (operator.error) {
            // If no valid operator, move on instead of returning error
            // This branch is the only valid way out of the loop
            ret.value.end = start + (str.length - ret.remaining.length);
            return ret;
        }
        ret.value.value.push(operator.value);
        ret.remaining = operator.remaining;

        parseMathValueValue(type, allParsers as any, ret.remaining, start + (str.length - ret.remaining.length), ret);
        if (ret.error) return ret;
    }
}

function parseBooleanValue(str: string, start: number): GetValueReturn<BooleanValue> {
    const ret: GetValueReturn<BooleanValue> = {
        value: { type: "boolean", value: null, start, end: null },
        remaining: str,
        error: null,
    };
    const matchKeyword = str.match(/^[\s\n\t]*(?<value>true|false)(?<remaining>[\s\S]*)/i);
    if (!matchKeyword?.groups?.value) return setError(ret, new SyntaxError(`Invalid boolean value. Please use "true"`));
    ret.value.end = start + (str.length - matchKeyword.groups.remaining.length);
    return setValue(ret, matchKeyword.groups.value as LowerStringBoolean, matchKeyword.groups.remaining);
}

function parseKeywordValue(options: string[], str: string, start: number): GetValueReturn<KeywordValue> {
    const ret: GetValueReturn<KeywordValue> = {
        value: { type: "keyword", value: null, start, end: null },
        remaining: str,
        error: null,
    };

    const regEx = new RegExp(`^[\\s\\n\\t]*(?<value>${options ? options.join("|") : "\\w+"})(?<remaining>[\\s\\S]*)`);
    const matchWord = ret.remaining.match(regEx);
    if (!matchWord?.groups?.value) return setError(ret, new SyntaxError(`Invalid keyword value`));
    ret.value.value = matchWord.groups.value;
    ret.value.end = start + (str.length - matchWord.groups.remaining.length);
    ret.remaining = matchWord.groups.remaining;
    return ret;
}

function parseDateValue(str: string, start: number): GetValueReturn<DateValue> {
    const ret: GetValueReturn<DateValue> = {
        remaining: str,
        error: null,
        value: {
            type: "date",
            value: null,
            start,
            end: null,
        },
    };

    const matchDate = ret.remaining.match(
        /^[\s\n\t]*["']?(?<value>\d{4}-\d\d-\d\d(T\d\d:\d\d(:\d\d)?)?)["']?(?<remaining>[\s\S]*)/,
    );
    if (!matchDate?.groups?.value) return setError(ret, new SyntaxError(`Invalid date value`));
    ret.value.value = matchDate.groups.value;
    ret.value.end = start + (str.length - matchDate.groups.remaining.length);
    ret.remaining = matchDate.groups.remaining;
    return ret;
}

function parseTimeValue(str: string, start: number): GetValueReturn<TimeValue> {
    const ret: GetValueReturn<TimeValue> = {
        value: { type: "time", value: null, start, end: null },
        remaining: str,
        error: null,
    };
    const matchTime = ret.remaining.match(/^[\s\n\t]*['"]?(?<value>\d\d:\d\d)['"]?(?<remaining>[\s\S]*)/);
    if (!matchTime?.groups?.value) return setError(ret, new SyntaxError(`Invalid time value`));
    ret.value.end = start + (str.length - matchTime.groups.remaining.length);
    return setValue(ret, matchTime.groups.value, matchTime.groups.remaining);
}

function parseRangeValue<T extends DateValue | TimeValue | NumberValue>(
    type: "date" | "time" | "number",
    valueParser: (str: string, start: number) => GetValueReturn<T extends TimeValue ? TimeValue : MathValue<T>>,
    str: string,
    start: number,
): GetValueReturn<RangeValue<T>> {
    const ret: GetValueReturn<RangeValue<T>> = {
        value: { type: "range", value: { start: null, end: null }, start, end: null },
        remaining: str,
        error: null,
    };

    const left = valueParser(str, start);
    if (left.error) return setError(ret, left.error);
    if (!/^[\s\n\t]*and/.test(left.remaining)) return setError(ret, new SyntaxError(`Invalid ${type} value`));
    ret.value.value.start = left.value;
    ret.remaining = left.remaining.replace(/^[\s\n\t]*and[\s\n\t]*/, ""); // Remove 'and'

    const right = valueParser(ret.remaining, start + (str.length - ret.remaining.length));
    if (right.error) return setError(ret, right.error);
    ret.value.value.end = right.value;
    ret.remaining = right.remaining;

    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseListValue(
    parsers: ((str: string, start: number) => GetValueReturn<Value>)[],
    str: string,
    start: number,
): GetValueReturn<ListValue<any>> {
    const ret: GetValueReturn<ListValue<any>> = {
        value: { type: "list", value: [], start, end: null },
        remaining: str,
        error: null,
    };

    // Remove opening bracket/parenthesis
    if (!/^[\s\n\t]*[(\[]/.test(ret.remaining))
        return setError(ret, new SyntaxError(`Lists must be enclosed in parentheses`));
    ret.remaining = ret.remaining.replace(/^[\s\n\t]*[(\[][\s\n\t]*/, ""); // Remove opening bracket/parenthesis

    while (!ret.remaining || !/^[\s\n\t]*[)\]]/.test(ret.remaining)) {
        const value = evaluateParsers(parsers, ret.remaining, start + (str.length - ret.remaining.length));
        if (!value) return setError(ret, new SyntaxError(`Invalid list value`));
        ret.value.value.push(value.value);
        ret.remaining = value.remaining.replace(/^[\s\n\t]*[,;][\s\n\t]*/, ""); // Replace separator in list
    }

    ret.remaining = ret.remaining.replace(/^[\s\n\t]*[)\]][\s\n\t]*/, ""); // Remove closing bracket/parenthesis
    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseEmbeddedValue(str: string, start: number): GetValueReturn<EmbeddedValue> {
    const ret: GetValueReturn<EmbeddedValue> = {
        value: { type: "embedded", value: { embeddedType: null, embeddedValue: null, values: [] }, start, end: null },
        remaining: str,
        error: null,
    };

    // Get list of strings, then parse the first value
    const list = parseListValue([parseStringValue], ret.remaining, start);
    ret.remaining = list.remaining;

    // Even if list has parse error, attempt to parse the embedded value
    if (!list.value.value.length) return setError(ret, new SyntaxError(`Invalid embedded value`));

    // So far all embedded values are numbers, so for now we only need to parse numbers
    const [type, rawValue] = list.value.value[0].value.split(":");
    if (!type || !rawValue) return setError(ret, new SyntaxError(`Invalid embedded value`));
    const value = parseFloat(rawValue);
    if (isNaN(value)) return setError(ret, new SyntaxError(`Invalid embedded value`));
    ret.value.value.embeddedType = type;
    ret.value.value.embeddedValue = value;

    // Now that we have attempted to parse the embedded value we can propagate any error in parsing the list
    if (list.error) return setError(ret, new SyntaxError(`Invalid embedded value`));

    // All embedded values require at least one value, so we might as well check for that
    if (list.value.value.length < 2) return setError(ret, new SyntaxError(`Invalid embedded value`));

    // No errors, accept all values
    ret.value.value.values = list.value.value.slice(1);
    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function parseCustomAttributeValue(str: string, start: number): GetValueReturn<CustomAttributeValue> {
    const ret: GetValueReturn<CustomAttributeValue> = {
        value: {
            type: "customAttribute",
            value: { name: null, operator: null, type: null, value: null },
            start,
            end: null,
        },
        remaining: str,
        error: null,
    };

    // Get list of strings, then split it up into a custom attribute token
    const list = parseListValue([parseStringValue], str, start);
    ret.remaining = list.remaining;

    // Any errors in list is ignored and replaced with CustomAttribute errors. But first see what we can get out of it
    // regardless of errors
    if (list.value.value.length >= 1) ret.value.value.name = list.value.value[0].value;
    if (list.value.value.length >= 2) ret.value.value.operator = list.value.value[1].value;
    if (list.value.value.length >= 3) ret.value.value.type = list.value.value[2].value;
    if (list.value.value.length >= 4) ret.value.value.value = list.value.value[3].value;

    // If we didn't have all the values we return our own error
    if (list.value.value.length < 3 || list.error)
        return setError(ret, new SyntaxError(`Invalid custom attribute value`));

    ret.value.end = start + (str.length - ret.remaining.length);
    return ret;
}

function validate(type: QLItemTypes, query: Query) {
    const queries = query.value.filter((item) => item.type === "query") as Query[];
    for (let q of queries) validate(type, q); // Validate children

    const clauses = query.value.filter((item) => item.type === "clause") as Clause[];

    // hasRelated and hasBound can only be used with other clauses
    const isRelatedOrBound = (term: Term) => ["hasRelated", "hasBound"].includes(term.value);
    const relOrBoundClauses = clauses.filter((item) => isRelatedOrBound(item.value.term)).length;
    if (relOrBoundClauses >= 1 && relOrBoundClauses === clauses.length) {
        throw new SyntaxError(`Terms "hasRelated" and "hasBound" can only be used in conjunction with other clauses`);
    }

    if (type === Item.Ids.Task) {
        // Task requires type and state
        const hasType = clauses.find((clause) => clause.value.term.value === "type");
        const hasState = clauses.find((clause) => clause.value.term.value === "state");
        if (!hasType || !hasState) throw new SyntaxError(`Task queries must include type and state`);
    }

    const deepDateHas = (math: MathValue<DateValue | KeywordValue>, predicate: (item: any) => boolean): boolean => {
        return !!math.value.find((item) => {
            if (item.type === "math") return deepDateHas(item, predicate);
            return predicate(item);
        });
    };
    for (let clause of clauses) {
        const term = clause.value.term.value;
        const termData = S25QLConst.terms[type][term];

        // Date Math: keyword "today" may only be used with term "createDt" and date ranges
        // Date Math: keyword "today" may not be used in conjunction with real dates
        if (termData.type === "date") {
            const date = clause.value.value as MathValue<DateValue | KeywordValue>;
            const hasDate = deepDateHas(date, (item) => item.type === "date");
            const hasToday = deepDateHas(date, (item) => item.value === "today");
            if (hasToday && term !== "createDt") throw new SyntaxError(`Keyword "today" cannot be used with this term`);
            if (hasToday && hasDate) throw new SyntaxError(`Keyword "today" cannot be used with static dates`);
        }

        // Range Date Math: keywords "today" and "start" may not be used in conjunction with real dates
        // Range Date Math: "today" only for the start, and "start" only for the end
        if (termData.type === "dateRange") {
            const range = clause.value.value as RangeValue<DateValue>;
            const startHasDate = deepDateHas(range.value.start, (item) => item.type === "date");
            const startHasToday = deepDateHas(range.value.start, (item) => item.value === "today");
            const startHasStart = deepDateHas(range.value.start, (item) => item.value === "start");
            const endHasDate = deepDateHas(range.value.end, (item) => item.type === "date");
            const endHasToday = deepDateHas(range.value.end, (item) => item.value === "today");
            const endHasStart = deepDateHas(range.value.end, (item) => item.value === "start");
            if (startHasToday && startHasDate)
                throw new SyntaxError(`Keyword "today" cannot be used with static dates`);
            if (endHasStart && endHasDate) throw new SyntaxError(`Keyword "start" cannot be used with static dates`);
            if (startHasStart) throw new SyntaxError(`Keyword "start" cannot be used in the start of a date range`);
            if (endHasToday) throw new SyntaxError(`Keyword "today" cannot be used in the end of a date range`);
        }

        // Validate length of fields
        const stringLength = ((clause?.value?.value?.value as string) || "").length;
        if (term === "keyword" && stringLength > 48)
            throw new SyntaxError(`"${term}" value may not exceed 48 characters`);
        if (term === "custAtrb") {
            const CA = clause.value.value as CustomAttributeValue;
            if (CA.value.type === "S" && String(CA.value.value)?.length > 80)
                throw new SyntaxError(`"${term}" string value may not exceed 80 characters`);
        }
        if (["name", "modifiedBy"].includes(term) && stringLength > 40)
            throw new SyntaxError(`"${term}" value may not exceed 40 characters`);
        if (["alienUID", "code"].includes(term) && stringLength > 100)
            throw new SyntaxError(`"${term}" value may not exceed 100 characters`);
        if (["title", "alienUID", "code"].includes(term) && stringLength > 248)
            throw new SyntaxError(`"${term}" value may not exceed 248 characters`);
        if (["ref", "formal"].includes(term) && stringLength > 78)
            throw new SyntaxError(`"${term}" value may not exceed 78 characters`);
        if (term === "address") {
            const values = (clause.value.value as ListValue<StringValue>).value;
            const ok = !values.find((val) => val.value?.length > 78);
            if (!ok) throw new SyntaxError(`"${term}" values may not exceed 78 characters`);
        }
    }
}

function validateQL(input: Flavor<string, "ql">, type: QLItemTypes): [ReturnType<typeof S25QLModeller.model>, string] {
    try {
        const query = S25QLTokenizer.tokenizer(input, type);
        return [S25QLModeller.model(type, query), null];
    } catch (error: any) {
        return [null, error.message];
    }
}

export const S25QLTokenizer = {
    tokenizer,
    safeTokenizer,
    parseValueMap: parseValueMap,
    validateQL,
};
