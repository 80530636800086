import { S25QLTokenizer, Tokenizer } from "./s25ql.tokenizer";
import { Flavor } from "../../pojo/Util";
import { QLTerm, S25QLConst } from "./s25ql.const";
import { S25Util } from "../../util/s25-util";
import { DropDownItem } from "../../pojo/DropDownItem";
import { SearchCriteriaType } from "../../pojo/SearchCriteriaI";
import { Item } from "../../pojo/Item";
import { ContactsAndRole } from "../s25-dropdown/multi-select/s25.dropdown.multi.contacts.component";
import { ObjectsAndRelationship } from "../s25-dropdown/multi-select/s25.dropdown.multi.relationships.component";
import { SuggestAddress } from "./s25ql.suggest.address.component";
import QLItemTypes = Tokenizer.QLItemTypes;
import Token = Tokenizer.Token;
import Query = Tokenizer.Query;
import Clause = Tokenizer.Clause;
import Conjunction = Tokenizer.Conjunction;
import Term = Tokenizer.Term;
import Operator = Tokenizer.Operator;
import ListValue = Tokenizer.ListValue;
import Value = Tokenizer.Value;
import CustomAttributeValue = Tokenizer.CustomAttributeValue;
import CustomAttributeOperator = Tokenizer.CustomAttributeOperator;
import EmbeddedValue = Tokenizer.EmbeddedValue;
import StringValue = Tokenizer.StringValue;
import Option = QLTerm.Option;

function suggest(type: QLItemTypes, inputValue: string, caret: Flavor<number, "index">) {
    // Tokenize whole string using safe tokenizer
    const tokenized = S25QLTokenizer.safeTokenizer(inputValue, type);

    // Get current clause and token
    let { query, clause, token } = getTokenAtIndex(tokenized.value, caret);
    token.hasCaret = true;
    const input = inputValue.slice(token.start, caret).trim();

    // Suggest new value
    let suggestion: QLSuggestion;
    if (token.type === "conjunction" || (clause.value?.value?.end && caret >= clause.end)) {
        if (clause?.value?.value) clause.value.value.hasCaret = false; // Remove caret from value token
        if (token.type === "conjunction") query.value.pop(); // Remove partial conjunction because we create a new one
        suggestion = suggestConjunction(type, query.value, input);
    } else if (token.type === "term") suggestion = suggestTerm(type, clause.value, input);
    else if (token.type === "operator") suggestion = suggestOperator(type, clause.value, input);
    else suggestion = suggestValue(type, clause.value, input);

    return { ...suggestion, query: tokenized.value };
}

function suggestConjunction(type: QLItemTypes, queryList: Query["value"], remaining: string): QLSuggestion {
    const { promise, resolve, reject } = S25Util.createPromise<boolean>();
    const conjunctions = S25QLConst.metaOps as Conjunction["value"][];

    const suggestion = suggestOption(conjunctions, remaining);
    suggestion.promise.then((conjunction: Conjunction["value"]) => {
        if (!conjunction) return;
        queryList.push({ type: "conjunction", value: conjunction, start: null, end: null, hasCaret: true });
        resolve(true);
    }, rejectSuggestion);

    return { suggestion: suggestion.suggestion, promise };
}

function suggestTerm(type: QLItemTypes, clause: Clause["value"], remaining: string): QLSuggestion {
    const { promise, resolve, reject } = S25Util.createPromise<boolean>();
    let terms = Object.keys(S25QLConst.terms[type]);

    const suggestion = suggestOption(terms, remaining);
    suggestion.promise.then((term: Term["value"]) => {
        if (!term) return;
        clause.term = { type: "term", value: term, start: null, end: null, hasCaret: true };
        resolve(true);
    }, rejectSuggestion);

    return { suggestion: suggestion.suggestion, promise };
}

function suggestOperator(type: QLItemTypes, clause: Clause["value"], remaining: string): QLSuggestion {
    const { promise, resolve, reject } = S25Util.createPromise<boolean>();
    const operators = S25QLConst.terms[type][clause.term.value].operations;

    const suggestion = suggestOption(operators, remaining);
    suggestion.promise.then((operator: Operator["value"]) => {
        if (!operator) return;
        clause.operator = { type: "operator", value: operator, start: null, end: null, hasCaret: true };
        resolve(true);
    }, rejectSuggestion);

    return { suggestion: suggestion.suggestion, promise };
}

function suggestValue(type: QLItemTypes, clause: Clause["value"], input: string): QLSuggestion {
    const { promise, resolve, reject } = S25Util.createPromise<boolean>();
    const termData = S25QLConst.terms[type][clause.term.value];

    if (!termData || termData.suggest === "none") return;

    let suggestion: QLSuggestion<any>;
    switch (termData.suggest) {
        case "option":
            const optionLabels = termData.options.map((opt: Option) => opt.label);
            suggestion = suggestOption(optionLabels, input);
            break;
        case "options":
            const optionsLabels = termData.options.map((opt: Option) => opt.label);
            suggestion = suggestOptions(optionsLabels, input, clause.value as ListValue<any>);
            break;
        case "object":
            suggestion = suggestObject(termData.objectType, input);
            break;
        case "objects":
            suggestion = suggestObjects(termData.objectType, input, clause.value as ListValue<any>);
            break;
        case "search":
            suggestion = suggestSearches(termData.searchType, input);
            break;
        case "attribute":
            suggestion = suggestCustomAttribute(input);
            break;
        case "contactRole":
            suggestion = suggestContactRole(input);
            break;
        case "relationship":
            suggestion = suggestRelationship(input);
            break;
        case "address":
            suggestion = suggestAddress(termData.objectType, input, clause.value as ListValue<any>);
            break;
    }

    suggestion.promise.then((data) => {
        if (!data) return;
        const tokenType = termData.type.match(/(List|Range)$/)?.[1]?.toLowerCase() || termData.type; // Turns lists and ranges into just "list" and "range"
        clause.value = { type: tokenType, value: data, hasCaret: true } as any as Value;
        resolve(true);
    }, rejectSuggestion);

    return {
        promise,
        suggestion: suggestion.suggestion,
    };
}

function suggestOption<T extends string>(options: T[], input: string): QLSuggestion<string> {
    if (!options?.length) return;
    const sortedOptions = bringIncludesToFront(options.slice(), input);
    const { promise, resolve, reject } = S25Util.createPromise<string>();
    return {
        promise,
        suggestion: {
            type: "option",
            data: { input, options: sortedOptions.map((opt) => toDropDownItem(opt)) },
            onSelect: (value: DropDownItem) => {
                resolve(String(value.itemId));
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestOptions<T extends string>(
    options: T[],
    input: string,
    list: ListValue<any>,
): QLSuggestion<ListValue<any>["value"]> {
    if (!options?.length) return;
    const sortedOptions = bringIncludesToFront(options.slice(), input);
    const { promise, resolve, reject } = S25Util.createPromise<ListValue<any>["value"]>();

    return {
        promise,
        suggestion: {
            type: "options",
            data: { input, options: sortedOptions.map((opt) => toDropDownItem(opt)) },
            onSelect: (value: DropDownItem[]) => {
                const list: ListValue<any>["value"] = value.map((item) => ({
                    type: "string",
                    value: item.itemName,
                }));
                resolve(list);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestObject(type: SearchCriteriaType["type"], input: string): QLSuggestion<string> {
    const { promise, resolve, reject } = S25Util.createPromise<string>();
    const customFilterValue = type === "contacts" ? "&itemName=username" : "";
    return {
        promise,
        suggestion: {
            type: "object",
            data: { input, objectType: type, customFilterValue },
            onSelect: (value: DropDownItem) => {
                const includeId = type !== "contacts";
                if (!includeId) return resolve(`${value.itemName}`);
                else return resolve(`${value.itemName} [ID:${value.itemId}]`);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestObjects(
    type: SearchCriteriaType["type"],
    input: string,
    list: ListValue<any>,
): QLSuggestion<ListValue<any>["value"]> {
    const { promise, resolve, reject } = S25Util.createPromise<ListValue<any>["value"]>();
    return {
        promise,
        suggestion: {
            type: "objects",
            data: { input, objectType: type },
            onSelect: (value: DropDownItem[]) => {
                const list: ListValue<any>["value"] = value.map((item) => ({
                    type: "string",
                    value: `${item.itemName} [ID:${item.itemId}]`,
                }));
                resolve(list);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestSearches(type: Item.Ids, input: string): QLSuggestion<string> {
    const { promise, resolve, reject } = S25Util.createPromise<string>();
    return {
        promise,
        suggestion: {
            type: "search",
            data: { input, searchType: type },
            onSelect: (value: DropDownItem) => resolve(`${value.itemName} [ID:${value.itemId}]`),
            rejectSuggestion: reject,
        },
    };
}

function suggestCustomAttribute(input: string): QLSuggestion<CustomAttributeValue["value"]> {
    const { promise, resolve, reject } = S25Util.createPromise<CustomAttributeValue["value"]>();
    return {
        promise,
        suggestion: {
            type: "attribute",
            data: { input },
            onSelect: (values: DropDownItem[]) => {
                let value = values[3]?.txt || "";
                if (value && !!Number(values[2].val)) value = `${values[3].txt} [ID:${values[3].val}]`; // If object, include ID
                const attribute: CustomAttributeValue["value"] = {
                    name: values[0] ? `${values[0].txt} [ID:${values[0].val}]` : "",
                    operator: (values[1]?.txt || "") as CustomAttributeOperator,
                    type: (values[2]?.val as string) || "",
                    value,
                };
                resolve(attribute);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestContactRole(input: string): QLSuggestion<EmbeddedValue["value"]> {
    const { promise, resolve, reject } = S25Util.createPromise<EmbeddedValue["value"]>();
    return {
        promise,
        suggestion: {
            type: "contactRole",
            data: { input },
            onSelect: (data: ContactsAndRole) => {
                const contacts = data.contacts
                    .filter((contact) => contact.name)
                    .map((contact) => ({
                        type: "string",
                        value: `${contact.name} [ID:${contact.id}]`,
                    }));
                const contactRole: EmbeddedValue["value"] = {
                    embeddedType: "role",
                    embeddedValue: data.role.id,
                    values: contacts as StringValue[],
                };
                resolve(contactRole);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestRelationship(input: string): QLSuggestion<EmbeddedValue["value"]> {
    const { promise, resolve, reject } = S25Util.createPromise<EmbeddedValue["value"]>();
    return {
        promise,
        suggestion: {
            type: "relationship",
            data: { input },
            onSelect: (data: ObjectsAndRelationship) => {
                const locations = data.objects.map((object) => ({
                    type: "string",
                    value: `${object.name} [ID:${object.id}]`,
                }));
                const relationship: EmbeddedValue["value"] = {
                    embeddedType: "relationship",
                    embeddedValue: data.relationship.id,
                    values: locations as StringValue[],
                };
                resolve(relationship);
            },
            rejectSuggestion: reject,
        },
    };
}

function suggestAddress(
    type: SearchCriteriaType["type"],
    input: string,
    list: ListValue<any>,
): QLSuggestion<ListValue<any>["value"]> {
    const { promise, resolve, reject } = S25Util.createPromise<ListValue<any>["value"]>();
    return {
        promise,
        suggestion: {
            type: "address",
            data: { input },
            onSelect: (address: SuggestAddress) => {
                const { type, contains, city, zip, country, phone, fax } = address;
                const list: ListValue<any>["value"] = [type, contains, city, zip, country, phone, fax].map((item) => ({
                    type: "string",
                    value: item,
                }));
                resolve(list);
            },
            rejectSuggestion: reject,
        },
    };
}

function getTokenAtIndex(token: Token, index: number): { query: Query; clause: Clause; token: Token } {
    let query: Query;
    let clause: Clause;
    while (true) {
        switch (token.type) {
            case "query":
                query = token as Query;
                token = token.value.findLast((token: Token) => token.start <= index);
                break;
            case "clause":
                clause = token as Clause;
                if (!token.value.term) return { query, clause, token }; // Return containing clause if no term
                if (!token.value.operator) return { query, clause, token: token.value.term }; // Return term if no operator
                if (token.value.operator.start > index) return { query, clause, token: token.value.term }; // Return term if operator is beyond index
                if (!token.value.value) return { query, clause, token: token.value.operator }; // Return operator if no value
                if (token.value.value.start > index) return { query, clause, token: token.value.operator }; // Return operator if value is beyond index
                token = token.value.value; // Switch to value token
                break;
            case "conjunction":
            case "profileCode":
            case "number":
            case "string":
            case "date":
            case "time":
            case "boolean":
            case "customAttribute":
            case "operator":
            case "keyword":
                return { query, clause, token }; // We already know that start is less than or equal to index
            case "list":
                if (!token.value?.length) return { query, clause, token }; // Return list if empty
                if (token.value[0].start > index) return { query, clause, token }; // Return list caret is before first token
                token = token.value.findLast((token: Token) => token.start <= index); // Switch to value token
                break;
            case "math":
                if (!token.value?.length) return { query, clause, token }; // Return math token if no value
                token = token.value.findLast((token: Token) => token.start <= index); // Switch to value token
                break;
            case "range":
                if (!token.value?.start) return { query, clause, token }; // Return range token if no start value
                if (!token.value?.end)
                    token = token.value.start; // Switch to start token if no end token
                else if (token.value.end.start > index)
                    token = token.value.start; // Switch to start token if end token is beyond index
                else token = token.value.end; // Switch to end token
                break;
            case "embedded":
                if (!token.value?.embeddedType || !token.value?.embeddedValue) return { query, clause, token }; // If we are missing either type or value we return the whole token
                if (!token.value.values.length) return { query, clause, token }; // If no values return whole token
                if (token.value.values[0].start > index) return { query, clause, token }; // If caret is before first value, return whole token
                token = token.value.values.findLast((token: Token) => token.start <= index); // Switch to current token
                break;
        }
    }
}

function bringIncludesToFront<T extends { includes: (str: string) => boolean }>(arr: T[], include: string) {
    return arr.sort((a, b) => (a.includes(include) && !b.includes(include) ? -1 : 1));
}

function toDropDownItem(id: string, name?: string): DropDownItem {
    return { itemId: id, itemName: name ? name : id };
}

function rejectSuggestion() {
    return false;
}

export type QLSuggestion<T = boolean> = {
    promise: Promise<T>; // Whether the user made a choice or not
    suggestion: {
        type: QLTerm.Suggest;
        data: {
            input: string;
            options?: DropDownItem[];
            objectType?: SearchCriteriaType["type"];
            chosenObjects?: DropDownItem[]; // For now s25-ng-dropdown-multi-search-criteria does not support picking chosen objects
            searchType?: Item.Ids;
            customFilterValue?: string;
        };
        inDropdown?: boolean; // To indicate whether the user used arrow keys to move into dropdown when suggesting a conjunction
        onSelect?: (...args: unknown[]) => void;
        rejectSuggestion: (reason?: any) => void;
    };
};

export const S25QLSuggest = {
    suggest,
};
